<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Add a File") }}</span>
          <v-spacer />
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 400px;"
      >
        <v-row class="pt-5">
          <v-col
            v-for="(f, index) in files"
            :key="index"
            :cols="isImage?'auto':12"
          >
            <v-card

              class="mt-3"
            >
              <v-card-text>
                <v-row>
                  <v-col
                    :cols="isImage?'auto':12"
                    :md="isImage?'auto':11"
                  >
                    <file-upload
                      v-if="!isImage"
                      v-model="files[index]"
                      :return-object="true"
                    />
                    <div
                      v-else
                      class="me-3 "
                    >
                      <div :style="{'width': width || '150px'}">
                        <v-text-field
                          v-model="files[index].description"
                          dense
                          outlined
                          :label="$t('Name')"
                          full-width
                        />
                      </div>
                      <file-upload
                        v-model="files[index]"
                        :return-object="true"
                        add-class="mx-auto justify-center"
                        :label="$t('Upload new photo')"
                        folder="images/packages/avatars/"
                        :btn="true"
                        :extra="false"
                        :hide-label="false"
                        icon-name="mdi-pencil"
                        :icon="false"
                        :show-download="false"
                        inputname="file"
                        :crop="true"
                        :readonly="false"
                        :aspect-ratio="ratio || 16/9"
                        :max-crop-height="cropHeight || 700"
                        :max-crop-width="cropWidth || 1400"
                        accept="image/*"
                      >
                        <template #input>
                          <v-avatar
                            :color="files[index].url ? '' : 'primary'"
                            :class="files[index].url ? '' : 'v-avatar-light-bg primary--text'"
                            :width="width || '150px'"
                            :height="height || '100px'"
                            rounded
                            class="mb-4"
                          >
                            <v-img
                              v-if="files[index].url"
                              :lazy-src="require(`@/assets/images/avatars/1.png`)"
                              :src="files[index].url || require(`@/assets/images/avatars/1.png`)"
                            ></v-img>
                            <span
                              v-else
                              class="font-weight-semibold text-5xl"
                            >{{ avatarText(files[index].description) }}</span>
                            <span
                              style="position: absolute;bottom: 10px; left: 10px"
                            >
                              <v-btn small icon>
                                <v-icon
                                  size="20"
                                  color="primary"
                                >
                              {{ icons.mdiCloudUploadOutline }}
                            </v-icon>
                              </v-btn>
                            </span>
                            <span
                              style="position: absolute;bottom: 10px; right: 10px"
                            >
                              <photo-view :images="files" :object="index" title="description" :width="600" />
                            </span>
                          </v-avatar>
                        </template>
                      </file-upload>
                      <v-btn
                        depressed
                        small
                        block
                        color="red"
                        @click.stop="removeObject(index, files[index])"
                      >
                        {{ $t('Delete') }}
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col
                    v-if="!isImage"
                    cols="12"
                    md="1"
                  >
                    <v-icon
                      color="danger"
                      @click.stop="removeObject(index, files[index])"
                    >
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-col>
                  <v-col
                    v-if="!isImage"
                    cols="6"
                  >
                    <v-text-field
                      v-model="files[index].description"
                      dense
                      hide-details
                      outlined
                      :label="$t('Name')"
                    />
                  </v-col>
                  <v-col
                    v-if="!isImage"
                    cols="6"
                  >
                    <v-text-field
                      v-model="files[index].url"
                      dense
                      hide-details
                      type="text"
                      outlined
                      :label="$t('Url')"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :loading="loading"
          color="info"
          class="me-3"
          @click="addFile"
        >
          {{ addButtonTitle || $t("Add File") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      dark
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-form>
</template>

<script>
import {
  mdiClose,
  mdiPlus,
  mdiPencilOutline,
  mdiPencilOffOutline,
  mdiCalendar,
  mdiCloudUploadOutline,
} from '@mdi/js'
import {
  getIndex,
  getItem,
} from '@core/utils'
import {
  computed,
  ref,
  watch,
} from '@vue/composition-api'
import {
  required,
  emailValidator,
} from '@core/utils/validation'
import axios from '@axios'
import {
  format,
} from 'date-fns'
import {
  avatarText,
} from '@core/utils/filter'

import FileUpload from '@/components/file_upload'
import { i18n } from '@/plugins/i18n'
import PhotoView from "@/components/PhotoView";

export default {
  components: {
    PhotoView,
    FileUpload,
  },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Array,
      default: () => [],
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    isImage: {
      type: Boolean,
      required: false,
    },
    addButtonTitle: {
      type: String,
      default: i18n.t('Add File'),
    },
    storeUrl: {
      type: String,
      default: '/fileupload',
    },
    filableType: {
      type: String,
      default: '',
    },
    filableId: {
      type: Number,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
    ratio: {
      type: Number,
      default: 16 / 9,
    },
    cropWidth: {
      type: Number,
      default: 1400,
    },
    cropHeight: {
      type: Number,
      default: 700,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, {
    emit,
  }) {
    const errorMessages = ref({})
    const valid = ref(false)
    const isFormPhotoActive = ref(false)
    const isSnackbarBottomVisible = ref(false)
    const snackBarMessage = ref('')
    const loading = ref(false)
    const search = ref(null)
    const minDate = new Date().toISOString().substr(0, 10)

    const files = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    files.value = files.value || []

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }
    const removeObject = (index, file) => {
      files.value.splice(index, 1)
      if (file.id) {
        axios.delete(`/files/${file.id}`)
      }
    }
    const addFile = () => {
      files.value = files.value || []
      files.value.push({ fileable_type: props.filableType, fileable_id: props.filableId })
    }

    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        axios.post(props.storeUrl, { files: files.value })
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = {
                  ...response.data.errors,
                }
              } else {
                // isSnackbarBottomVisible.value = true
                // snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }

            files.value = response.data

            // emit('update:is-form-active', false)

            emit('cancel')
            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = {
                ...error.data.errors,
              }
            }
          })
      } else {
        // validate()
      }
    }

    // onMounted(searchParticular)

    return {
      files,
      minDate,
      resetItem,
      isFormPhotoActive,
      search,
      errorMessages,
      onSubmit,
      valid,
      loading,
      avatarText,
      addFile,
      removeObject,
      isSnackbarBottomVisible,
      snackBarMessage,

      // validation
      validators: {
        required,
        emailValidator,
      },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPlus,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
