<template>
  <v-dialog
    v-model="isFormActive"
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        v-if="!showButton"
        small
        dense
        depressed
        outlined
        style="min-width: 67px; max-width: 300px"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-title>
          {{ text }}
        </v-list-item-title>
      </v-list-item>
      <v-btn
        v-else
        color="primary"
        class="ml-1 "
        :small="icon"
        :dense="icon"
        :icon="icon"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>{{ icons.mdiEye }}</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ images[index][title] }} <v-spacer /> <v-icon color="red" @click.stop="isFormActive = false">
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-img
          style="max-width: 100%; max-height: 100%"
          contain
          :src="images[index][url]"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

// eslint-disable-next-line object-curly-newline
import {
  mdiEye,
  mdiClose,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'

export default {
  components: { },
  model: {
    prop: 'object',
  },
  props: {
    object: {
      type: Number,
      default: () => [],
    },
    images: {
      type: Array,
      default: () => [],
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: 'url',
    },
    title: {
      type: String,
      default: 'title',
    },
    text: {
      type: String,
      default: 'View',
    },
  },
  setup(props, { emit }) {
    const index = computed({
      get: () => props.object,
    })
    const isFormActive = ref(false)

    return {
      isFormActive,
      index,
      icons: {
        mdiEye,
        mdiClose,
      },
    }
  },
}
</script>
